import * as truncate from "truncate"

import type {
  AssetId,
  Maybe,
  Organization,
  OrganizationIssuesQuery,
  OrganizationMetadata,
} from "query/graphql"
import {
  TALLY_DEFAULT_LOGO_URL,
  TALLY_DEFAULT_SOCIAL_PREVIEW,
} from "common/constants/logo"
import { getAssetIdParams } from "web3/helpers/assetId"
import { getOrgSocialPreview } from "organization/constants/org-social-preview"

export const getOrganizationIcon = (
  organization?: Maybe<{
    metadata?: Maybe<Pick<OrganizationMetadata, "icon">>
  }>,
): string => {
  if (!organization?.metadata?.icon) {
    return TALLY_DEFAULT_LOGO_URL
  }

  const { icon } = organization.metadata

  return icon?.length ? icon : TALLY_DEFAULT_LOGO_URL
}

export const getOrganizationDescription = (
  organization?: Maybe<Organization>,
): string => {
  return organization?.metadata?.description
    ? truncate.default(organization.metadata.description, 80)
    : ""
}

export const isMultiChainOrganization = (
  organization: Pick<Organization, "chainIds">,
): boolean => {
  if (!organization.chainIds) return false

  const uniqueChainIds = new Set(organization.chainIds)

  return (
    organization.chainIds.length > 1 &&
    organization.chainIds.length === uniqueChainIds.size
  )
}

export const getOrganizationIssues = (
  issues: OrganizationIssuesQuery["issues"],
  isFilterCustomIssues: boolean,
): OrganizationIssuesQuery["issues"] | undefined => {
  if (!issues) {
    return []
  }

  if (!isFilterCustomIssues) {
    return issues
  }

  return issues.filter((issue) => issue?.organizationId !== "0")
}

export const isMultiChainTokenIds = (tokenIds?: AssetId[]): boolean => {
  if (!tokenIds || tokenIds.length === 0) {
    return false
  }

  const isMultiChain =
    tokenIds.reduce((acc: string[], tokenId) => {
      const { chainId } = getAssetIdParams(tokenId)

      if (!acc.includes(chainId)) {
        acc.push(chainId)
      }

      return acc
    }, []).length > 1

  return isMultiChain
}

export const getOrganizationSocialPreviewImage = (
  organization: Organization,
) => {
  const socialPreview = getOrgSocialPreview(organization.id)

  if (socialPreview) {
    return socialPreview.image
  }

  const icon = getOrganizationIcon(organization)

  if (icon) {
    return icon
  }

  return TALLY_DEFAULT_SOCIAL_PREVIEW
}
