import { useMemo, useContext, createContext } from "react"
import type { ReactElement, ReactNode } from "react"

import type { FeatureState, Governor, Maybe, Organization } from "query/graphql"
import { OrganizationRole, useOrganizationContextQuery } from "query/graphql"
import { useSession } from "session/providers/SessionProvider"
import { isMultiChainOrganization } from "organization/helpers/organization"

export enum FeatureFlag {
  // Enable the Welcome page on the DAO
  WelcomePage = "WELCOME_PAGE",

  // DAO new layout
  NewLayout = "NEW_LAYOUT",

  // DAO legacy layout
  LegacyLayout = "LEGACY_LAYOUT",

  // Do Not Add Fee to transfers
  ExcludeTallyFee = "EXCLUDE_TALLY_FEE",

  // The Org should display only the custom areas added but not the Tally default ones
  CustomFocusAreasOnly = "CUSTOM_FOCUS_AREAS_ONLY",

  // Hide the Treasury component in DAO's home page, the Treasury page and the Treasury nav bar
  // If the flag doesn't exist, then it will use the default value set in isFeatureFlagOn
  HideTreasury = "HIDE_TREASURY",

  // Hide the Transfer action option in the Create Proposal page
  HideTransferAction = "HIDE_TRANSFER_ACTION",

  // Use the Wormhole token bridge delegation
  UseWormholeTokenBridgeDelegation = "USE_WORMHOLE_TOKEN_BRIDGE_DELEGATION",

  // Enable compliance note on proposal creation, meant for Arbitrum only
  DisplayComplianceNotice = "DISPLAY_COMPLIANCE_NOTICE",

  // Shows the stake page for unistaker
  ShowUnistaker = "SHOW_UNISTAKER",

  // Shows the stake page for Arbitrum Staking (like Unistaker)
  ShowArbStaking = "SHOW_ARB_STAKING",

  // Shows the stake page for LST Staking
  ShowLSTStaking = "SHOW_LST_STAKING",

  // Enable delegation week features
  DelegationWeek = "DELEGATIONWEEK",

  // Enable predelegation week features
  PreDelegationWeek = "PREDELEGATIONWEEK",

  // Sort of delegates by priority (featured)
  DelegatesSortedByPriority = "DELEGATES_SORTED_BY_PRIORITY",

  // Sort of delegates by random (default)
  DelegatesSortedByRandom = "DELEGATES_SORTED_BY_RANDOM",

  // enable security council banner for Arbitrum organization
  SecurityCouncilBanner = "SECURITY_COUNCIL_BANNER",

  // hide delegate button
  HideDelegateButton = "HIDE_DELEGATE_BUTTON",

  // hide delegate button in a Solana account
  HideDelegateSolanaButton = "HIDE_DELEGATE_SOLANA_BUTTON",

  // Hide Delegated Tokens count
  HideDelegatedTokensCount = "HIDE_DELEGATED_TOKENS_COUNT",

  // Display Staker Beta Announcement banner
  DisplayStakerBetaAnnouncementBanner = "DISPLAY_STAKER_BETA_ANNOUNCEMENT_BANNER",
}

export const isFeatureFlagOn = (
  features: FeatureState[] | null | undefined,
  name: string,
) => {
  // find feature of this Organization looking by name
  const featureState = features?.find(
    (feature: FeatureState) =>
      feature.name.toLowerCase() === name.toLowerCase(),
  )

  if (featureState) return featureState.enabled

  return false
}

type OrganizationContextValues = {
  myRole: OrganizationRole | null | undefined // TODO(@ntourne): Improve it
  isAdminRole: boolean
  isLoading: boolean
  isFeatureFlagOn: (name: string) => boolean
  refetch: () => void
  organization?: Maybe<Organization>
  governors?: Governor[]
  isMultiChain: boolean
}

const OrganizationContext = createContext<
  OrganizationContextValues | undefined
>(undefined)

type OrganizationProviderProps = {
  children: ReactNode
  organization?: Maybe<Organization>
  governors?: Governor[]
}
export const OrganizationProvider = ({
  children,
  organization,
  governors = [],
}: OrganizationProviderProps): null | ReactElement => {
  const { isLoggedIn } = useSession()

  const id = organization?.id

  const { data, refetch, isLoading } = useOrganizationContextQuery({
    input: {
      id,
    },
  })

  const _isFeatureFlagOn = (name: string) => {
    return isFeatureFlagOn(data?.organization?.features, name)
  }

  const isAdminRole = useMemo(() => {
    if (!data?.organization || !isLoggedIn) return false

    return (
      data?.organization.myRole === OrganizationRole.Admin ||
      data?.organization.myRole === OrganizationRole.Superadmin
    )
  }, [data, isLoggedIn])

  const value = {
    refetch,
    myRole: data?.organization?.myRole,
    isAdminRole,
    isLoading,
    organization,
    governors,
    isFeatureFlagOn: _isFeatureFlagOn,
    isMultiChain: data?.organization
      ? isMultiChainOrganization(data.organization)
      : false,
  }

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = (): OrganizationContextValues => {
  const context = useContext(OrganizationContext)

  if (!context)
    throw new Error(
      "OrganizationContext must be used within OrganizationProvider",
    )

  return context
}
