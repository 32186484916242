import type { Signer } from "@wagmi/core"
import invariant from "tiny-invariant"
import { SiweMessage } from "siwe"

import { fetchNonce } from "common/helpers/fetch"
import { getMainnetReference } from "web3/helpers/chainReference"

export type GenerateMessageOutput = {
  message: string
  nonceToken: string
  signature: string
}

export const generateMessage = async (
  signer: Signer,
  statement: string,
): Promise<GenerateMessageOutput> => {
  invariant(statement?.trim(), '"statement" must be a non-empty string')

  const nonceResponse = await fetchNonce()

  invariant(nonceResponse, '"nonce" must be defined')

  const { nonce, expirationTime, issuedAt, nonceToken } = nonceResponse

  const address = await signer.getAddress()

  const siweMessage = new SiweMessage({
    uri: process.env.NEXT_PUBLIC_TALLY_SIWE_URI,
    nonce,
    expirationTime,
    issuedAt,
    domain: window.location.host,
    address,
    version: "1",
    chainId: getMainnetReference(),
    statement,
  })

  const message = siweMessage.prepareMessage()

  const signature = await signer.signMessage(message)

  return { message, nonceToken, signature }
}
