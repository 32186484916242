import { useMemo } from "react"

import { useMultichainAccount } from "web3/hooks/useMultichainAccount"
import { useMe } from "user/providers/MeProvider"

export const useAddress = (): string | undefined => {
  const { multichainAccount } = useMultichainAccount()
  const me = useMe()

  const signerAddress = useMemo(() => {
    // me.address works for both EOA or Safe (if user switch to some specific Safe)
    if (me?.address) {
      return me.address
    }

    // Otherwise, always use address from wallet
    return multichainAccount?.address
  }, [me?.address, multichainAccount?.address])

  return signerAddress ?? undefined
}
