import abbreviate from "@pqt/abbreviate"
import { BigNumber } from "@ethersproject/bignumber"
import { formatUnits } from "@ethersproject/units"

import { limitNumberDecimals } from "common/helpers/number"

export const stringToBigNumber = (tokenValue: string): BigNumber => {
  try {
    return BigNumber.from(tokenValue)
  } catch {
    return BigNumber.from(0)
  }
}

export const bigNumberToNumber = (
  bigNumber: BigNumber,
  decimals: number,
): number => {
  const isNftGovernance = (decimals: number) => decimals === 0

  const number = isNftGovernance(decimals)
    ? bigNumber.toNumber()
    : Number(formatUnits(bigNumber, decimals))

  return number
}

export const getWeightLabel = (
  _weight: BigNumber | string,
  decimals: number,
  isAbbreviated = true,
  decimalPlaces = 2,
): string => {
  try {
    const weight =
      typeof _weight === "string" ? stringToBigNumber(_weight) : _weight
    // Some DAOs has a BigNumber so big that it causes an overflow error trying to convert to a number
    // In this Linear issue you have more details on why this overflow should be handled in FE side
    // https://linear.app/withtally/issue/TALLY-2739/contract-parameters-section-doesnt-load-in-metastonez-dao

    const number = bigNumberToNumber(weight, decimals)

    if (number > 1000) {
      return isAbbreviated
        ? abbreviate(number, decimalPlaces)
        : String(limitNumberDecimals(number, 0))
    }

    if (number === 0) return String(0)
    if (number > 0.0001)
      return String(limitNumberDecimals(number, decimalPlaces))

    return "< 0.0001"
  } catch (err) {
    console.warn("Error getting weight label", err)
    // If there is any issue (i.e. overflow), then it returns "?" label

    return "?"
  }
}

export const getTotalWeight = (_weights: (string | BigNumber)[]): BigNumber => {
  const weights = _weights.map((weight) =>
    typeof weight === "string" ? stringToBigNumber(weight) : weight,
  )

  const totalWeight = weights.reduce((currentTotal, weight) => {
    return currentTotal.add(weight)
  }, BigNumber.from(0))

  return totalWeight
}

export const getTotalWeightNumber = (
  weights: string[],
  decimals: number,
): number => {
  const totalWeight = getTotalWeight(weights)
  const totalWeightNumber = bigNumberToNumber(totalWeight, decimals)

  return totalWeightNumber
}

export const getWeightPercentage = (
  amount: BigNumber | string,
  total: BigNumber | string,
  decimals = 18,
): number => {
  const numberAmount = bigNumberToNumber(
    typeof amount === "string" ? stringToBigNumber(amount) : amount,
    decimals,
  )
  const totalAmount = bigNumberToNumber(
    typeof total === "string" ? stringToBigNumber(total) : total,
    decimals,
  )

  return (numberAmount * 100) / totalAmount
}

export const formatNumber = (input: string) => {
  try {
    const number = parseInt(input, 10)

    if (!isNaN(number)) {
      return number.toLocaleString()
    } else {
      return input
    }
  } catch (error) {
    return input
  }
}
